import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import TSSeriesImage from "../../components/logicom-images/ThermalShockSeries.js"

const ThermalShock = () => (
    <Layout>
      <SEO title="Thermal Shock Test" />
      <Container>
        <Row><Col><h2>Thermal Shock Test</h2></Col></Row>
        <Row>
          <Col>            
              <p>Thermal shock testing that subjects a product to alternating extremes of high and low temperatures in order to observe changes in product characteristics and failure occurrence caused by different materials and their thermal expansion coefficients. Using powerful air-to-air thermal shock chambers, such as those offered by Thermotron, manufacturers are able to place their product inside the product carrier and observe as the product automatically is transferred between two or three separately controlled temperature zones, depending on the configuration of chamber chosen.</p>              
          </Col>
        </Row>
        <Row><Col><p>Thermotron offers several configurations of thermal shock chambers capable of meeting a wide variety of testing needs:</p></Col></Row>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>
            <TSSeriesImage/>
          </Col>
          <Col>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><b>Vertical Orientation</b> – two-zone chamber has hot and cold zones that can be controlled independently. A single basket product carrier moves between the hot and cold zones, subjecting the product to dramatic changes in temperature.</li>
              <li class="list-group-item"><b>Horizontal Orientation</b> – three-zone thermal shock chamber utilizes controlled areas for hot, ambient and cold temperatures. The product is moved from ambient to hot or ambient to cold zones. The chamber can be programmed to include a dwell period in the ambient zone if desired.</li>
              <li class="list-group-item"><b>Double Duty Vertical</b> – hot zones above and below the cold zone and a two compartment product carrier that simultaneously moves products between the hot and cold zones. This configuration makes better use of the chamber’s refrigeration system, increases machine efficiency and allows for more products to be tested in the same amount of time.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <br/>
            <p>Most thermal shock chambers feature cascade refrigeration systems for full cooling using only mechanical refrigeration. All mechanically cooled models can also be equipped with a liquid nitrogen (LN2) or liquid carbon dioxide (CO2) boost system. Models cooled entirely by LN2 or CO2 are also available in certain configurations.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
  
  export default ThermalShock
